import React from 'react';

function Index() {
    return (
        <div className="content">
            <div className="title-container">
                <h1>RYAN SMITH</h1>
                <h2>Software Engineer,  UX/UI Designer</h2>
            </div>
        </div>
    );
};

export default Index;



