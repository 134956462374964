import git from "../images/socials/github.png";
import linkedin from "../images/socials/linkedin.png";
import email from "../images/socials/email.png";

const Socials = [
    {
        id: 1,
        alt: "LinkedIn",
        img: linkedin,
        link: "https://www.linkedin.com/in/ryan-smith1/"
    },
    {
        id: 2,
        alt: "GitHub",
        img: git,
        link: "https://github.com/PuddlesRS"
    },
    {
        id: 3,
        alt: "Ryan.smith.ux@gmail.com",
        img: email,
        link: "mailto:Ryan.smith.ux@gmail.com"
    }
];

export default Socials;